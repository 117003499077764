/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Anchor } from 'antd';

import Head from '../../components/Head';
import StandardPage from '../../components/StandardPage';
import CTASection from '../../components/CTASection';
import LearnMore from '../../components/LearnMore';
import ShareRow from '../../components/ShareRow';

const { Link } = Anchor;

const LetterSection = () => {
  const anchorStyle = { visibility: 'hidden', cursor: 'none' };
  return (
    <StandardPage>
      <Head
        title="How Direct Primary Care Will Solve the Healthcare Crisis"
        description="DPC Nation is an educational resource for patients who are tired of suffering in a broken healthcare system. Direct primary care has already helped over a quarter million Americans stay healthier, save money, and form a real relationship with their doctor."
        url="/essays/dpc-solves-the-healthcare-crisis/"
        image="/img/banner.png"
      />
      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <a id="letter" style={anchorStyle} />
        <div className="row as jc wide">
          <div className="gutter">
            <Anchor offsetTop={150}>
              <Link href="#crisis" title={'The healthcare crisis'}>
                <Link href="#howinsuranceworks" title="The business of insurance" />
                <Link href="#overcoverage" title="Over-coverage" />
                <Link href="#coding" title={'The birth of medical coding'} />
                <Link href="#gaming" title={'Gaming the system'} />
                <Link href="#overhead" title={'The rise of the administrators'} />
                <Link href="#consolidation" title={'Hospital consolidation'} />
                <Link href="#prices" title={'Why prices are going up'} />
              </Link>
              <Link href="#victims" title={'The victims of the crisis'}>
                <Link href="#patients" title={'Patients'} />
                <Link href="#doctors" title={'Doctors'} />
              </Link>
              <Link href="#dpc" title={'How DPC solves the crisis'} />
              <Link href="#cta" title={'Getting started with DPC'} />
            </Anchor>
          </div>
          <div className="wide readable col js ac blog">
            <div style={{ height: '100px' }} />
            <a id="crisis" style={anchorStyle} />
            <img src="/img/robot.svg" style={{ width: '200px' }} alt="Doctors" />
            <h1 className="subtitle">How DPC Solves the Healthcare Crisis</h1>
            <p>Healthcare in the Unites States is broken. </p>
            <p>
              Actually, "broken" doesn't quite capture it. It's a roiling cesspool of greed,
              perverse incentives, incompetence, and a lack of interest in providing great care to
              the people who need it.
            </p>
            <p>So let's get into it.</p>
            <a id="howinsuranceworks" style={anchorStyle} />
            <h3 className="subsubtitle ">Insurance companies</h3>
            <p>
              Insurance companies are widely blamed for causing the healthcare crisis. And yeah—they
              certainly deserve a big part of the blame.
            </p>
            <p>
              The whole point of insurance (of any kind) is to avoid a huge bill if something
              catastrophic happens. By paying a little bit of money on a regular basis, you avoid
              paying a lot of money when something really bad happens. If an insurance company is
              paying for something that is normal and routine, they're just a useless middleman.
            </p>
            <a id="overcoverage" style={anchorStyle} />
            <h3 className="subsubtitle">Over-coverage</h3>
            <p>
              Here's the thing: insurance companies have a fixed profit margin; they take a cut of
              all the money that flows through them. The only way for them to make more money is to
              process more dollars. So they started insuring things that didn't need to be insured.
            </p>
            <p>
              Would you use your car insurance to pay for an oil change? No—you only use it when you
              get in an accident. You pay for routine maintenance yourself. What if your car insurer
              offered to pay for your oil changes, but only if you go to High Price Lube and pay a
              pre-negotiated "discounted" rate of $200?
            </p>
            <p>
              This is how the system works in America; you use your health insurance for everything.
              Annual physicals? Pay with insurance. Routine blood tests? Pay with insurance. Putting
              a bandaid on a scratch? Pay with insurance.
            </p>
            <p>This is the madness of health insurance in America.</p>

            <a id="coding" style={anchorStyle} />
            <h3 className="subsubtitle">The seeds of crisis</h3>
            <p>
              In 1966, the American Medical Association published the first edition of the Current
              Procedural Terminology (CPT) manual. It assigned a short, standard numerical "CPT
              code" to every known medical procedure. The insurance industry loved it.
            </p>
            <p>
              By the 80s, Medicare and private insurance companies had deeply integrated CPT codes
              into their claims processing system. They were a convenient shorthand way to itemize
              all the services performed in a doctor visit. All hospitals and clinics had to use CPT
              codes in their insurance claims to get paid. And for a while, things were good.
              Everyone spoke the same language and the system was working.
            </p>
            <a id="gaming" style={anchorStyle} />
            <h3 className="subsubtitle ">Gaming the system</h3>
            <p>Then a terrible thing happened. Hospitals learned how to game the system.</p>
            <p>
              They started submitting itemized lists containing dozens of CPT codes, even for simple
              visits. They developed medical record software that was geared entirely towards
              maximizing revenues, instead of tracking medically relevant information. They started
              training their doctors to use certain "billable phrases" in the their medical records.
              They started recommending MRIs and blood tests willy nilly, even if they weren't
              medically necessary. They started choosing a more lucrative surgery over a cheaper,
              safer option.
            </p>
            <a id="overhead" style={anchorStyle} />
            <h3 className="subsubtitle ">The rise of adminstrators</h3>
            <p>
              There are a thousand other ways hospitals have managed to “optimize” their insurance
              billing. There’s even an entire profession dedicated to the art of maximizing hospital
              revenue: “medical coder”.
            </p>
            <p>
              Of course, the insurance companies realized they were getting played. So they tried to
              fix their systems they only way they knew how: with more elaborate coding systems,
              more reporting requirements, and more paperwork. As the systems grew in complexity,
              hospitals starting requiring more and more administrators to handle the additional
              requirements. How many more? See for yourself.
            </p>
            <img
              src="/img/overhead.jpg"
              className="mv32"
              style={{ borderRadius: '3px', width: '80%' }}
              alt="growth of administrative overhead"
            />
            <p>
              In the past 40 years, there has been a 32x increase in hospital administrators,
              compared to a 2.6x increase in the total number of doctors. That's not good.
            </p>

            <a id="consolidation" style={anchorStyle} />
            <h3 className="subsubtitle ">Hospital consolidation</h3>
            <p>
              These days, it's so hard for a doctor to get reimbursed by an insurance company that
              many private practices and pharmacies are going out of business. Managing the
              complexity of the modern insurance system requires a huge, well-funded
              bureaucracy—something only big hospital systems can afford.
            </p>
            <p>
              This is the core driver behind "hospital consolidation", one of the most harmful
              healthcare trends of the past decade. Big corporate "health systems" have been buying
              clinics, private practices, and even other hospitals. By aggregating a bunch of
              facilities under one umbrella, hospitals can implement their billing practices more
              widely, steer patients towards facilities and specialists they control, and decrease
              operating costs.
            </p>
            <a id="prices" style={anchorStyle} />
            <h3 className="subsubtitle ">Increasing prices</h3>
            <p>
              As these health systems grew to encompass entire counties or states, they gained a
              huge advantage in negotiations with insurance companies.
            </p>
            <p>
              A fair market price for a knee replacement is about $30k; this covers the hospital’s
              cost of labor and materials plus a fair profit margin. If you get a knee replacement
              within a huge health system, they'll probably try to bill your insurance company over
              $100k. If the insurance company complains, the hospital will offer them an amazing
              deal: a massive 50% discount! The insurance company happily agrees to pay $50k—but
              they’re still paying $20k more than the market price. Over time, prices drift upwards
              as insurance companies get accustomed to paying ever higher prices.
            </p>
            <p>
              In recent years, this phenomenon has reach the point of absurdity. Here are some
              examples of real medical bills (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/@RosenthalHealth/how-economic-incentives-have-created-our-dysfunctional-us-medical-market-b681c51d6436">
                source
              </a>
              ):
            </p>
            <p>
              <blockquote>
                A $17 Tylenol pill in the hospital. A $98 ice pack applied during physical therapy.
                A $70 additional “mileage charge” for a 15-minute ambulance ride. A $10,000 “trauma
                team activation fee,” when a triage nurse summoned surgeons to the emergency room. A
                $1000 “rooming-in charge” to a mother who opted to keep her newborn in her room,
                rather than having him admitted to the new-born nursery.
              </blockquote>
            </p>
            <p>
              This is the largest contributor to the last 10 years of rising healthcare costs: huge,
              consolidated, corporate health systems continually charging more for the same
              services, because they can.
            </p>

            <div style={{ height: '100px' }} />
            <a id="victims" style={anchorStyle} />

            <img src="/img/doctor.svg" style={{ width: '200px' }} alt="Doctor illustration" />

            <h2 className="subtitle">The victims of the crisis</h2>
            <p>
              That's the context of the current crisis. Insurance and health systems are locked in a
              decades-long price war where both sides just get richer. So who are the losers?
            </p>
            <a id="patients" style={anchorStyle} />
            <h3 className="subsubtitle ">Patients</h3>
            <p>
              This one is obvious. If you've had a medical appointment in the last 15 years you've
              seen these problems firsthand.
            </p>
            <ol style={{ marginLeft: '30px' }}>
              <li style={{ margin: '15px 0px 35px 0px' }}>
                <span style={{ fontWeight: '600' }}>Lack of access</span>. You'll need to book any
                doctor's appointment weeks in advance. Once you arrive, you'll probably still wait
                two hours in the waiting room. And once your number is finally called, you'll have a
                brief, unsatisfying visit with a doctor that spends the whole time looking at a
                computer. If you have a followup question, there's no recourse—just schedule another
                visit next month.
              </li>
              <li style={{ margin: '15px 0px 35px 0px' }}>
                <span style={{ fontWeight: '600' }}>Expensive insurance</span>. As hospitals bill
                more and more for the same services, insurance premiums go up accordingly. This is
                great for both insurance companies and hospitals—but at the end of the pay patients
                foot the bill. Insurance premiums have increased faster than wages for the last{' '}
                <em>two decades</em>. Plus, most plans require you to pay an additional fee (a
                "co-pay") for every medical service you receive.{' '}
              </li>
              <li style={{ margin: '15px 0px 35px 0px' }}>
                <span style={{ fontWeight: '600' }}>Surprise bills</span>. Even if you have great
                insurance, you're still at risk of getting hit with big surprise medical bills. Your
                insurance plan will only cover care you receive "in network". Yet these networks are
                confusing and ever-changing. The concept of selective "narrow networks" for
                insurance plans was devised as a negotiating tactic—insurance companies would only
                work with hospitals that offered them the best prices. Now patients are paying the
                price.
              </li>
              <li style={{ margin: '10p5 0px 35px 0px' }}>
                <span style={{ fontWeight: '600' }}>Bad care</span>. Apparently providing good,
                compassionate healthcare is too expensive, so health systems have optimized it away.
                The average doctor visit in an American hospital only lasts 12 minutes. Hospitals
                pressure doctors to prescribe more medications, order more tests, and bill for more
                services—even if none of of it is medically necessary. And still more Americans die
                from poor care quality than any other comparable first-world country.
              </li>
            </ol>
            <a id="doctors" style={anchorStyle} />
            <h3 className="subsubtitle ">Doctors</h3>
            <p>
              Doctors are another victim of the current crisis. The overwhelming majority of doctors
              get into medicine to help people. If you’ve had a bad experience with a doctor who
              doesn’t seem to care, it’s because they’re in an impossible situation.
            </p>
            <p>
              Hospitals treat their doctors like cogs in a machine; they don’t care about the
              quality of care the doctor provides or the satisfaction of the patient. Physicians
              exmployed by hospitals have extremely low job satisfaction rates. These short,
              impersonal visits take a huge emotional toll on doctors. In surveys, 40% of doctors
              say they plan to leave the profession in the 49% of doctors say they wouldn't
              recommend a career in medicine to their children.
            </p>
            <p>
              If you've had a string of bad experiences, it’s easy to blame it on bad doctors. But
              it’s virtually impossible for a physician to operate in an empathetic, caring way
              inside of a modern American hospital. The big corporate health systems have optimized
              away the doctor-patient relationship in the name of higher profits.
            </p>

            <div style={{ height: '100px' }} />
            <a id="dpc" style={anchorStyle} />
            <h2 className="subtitle">DPC saves the day</h2>
            <p>
              DPC is a new way to get high quality, comprehensive healthcare from a doctor who
              cares, all for a low, flat monthly fee.
            </p>
            <p>
              DPC doctors ditch the broken healthcare system, leave their hellish hospital jobs, and
              start providing care at reasonable prices. It’s that simple. No insurance, no
              corporate health system, no middlemen. Just doctors and patients.
            </p>
            <p>
              Practically speaking, DPC is a membership model for healthcare. Every patient pays a
              flat monthly fee to become a member of the DPC practice. What are the benefits of
              membership? Put simply: access to a doctor that cares about you.
            </p>
            <p>
              Your DPC doctor is there when you need them. You get unlimited office visits at no
              additional charge—and they'll be as long as you need. Typically you’ll be able to book
              a same-day or next-day appointment, sometimes directly through the practice’s website.
              Often you’ll have access to your doctor’s cell phone number, so you’ll be able to call
              or text them any medical questions as needed. Simple diagnostics like strep tests can
              often be performed in office for a small additional charge (or no charge at all). If
              you have a major issue, your doctor will coordinate any specialist referrals or
              hospital care.
            </p>
            <p>
              Not to mention: the membership fee can often pay for itself. Most DPC practices have
              an established relationship with local laboratories and radiology centers. That means
              you can get blood tests, pathology screens, X-rays, MRIs, and more for low cash
              prices. Plus, most DPC practices dispense medications in-house at near-wholesale
              prices, so you both save money and avoid extra trips to the pharmacy.
            </p>
            <p>
              In short: being a member of a DPC practice is like having a doctor in the family. Your
              doctor will always be in your corner, working to keep you as healthy as possible. It’s
              an amazing feeling.
            </p>
            <ShareRow label />

            <div style={{ height: '100px' }} />
            <CTASection />
            <div style={{ height: '100px' }} />
            <LearnMore home faq market />
          </div>
          <div className="gutter" />
        </div>
      </div>
    </StandardPage>
  );
};

export default LetterSection;
